import PrimaryButton from "@/components/common/PrimaryButton";
import { LINK_OF_ALL_PAGES } from "const/common";
import Head from "next/head";
import Image from "next/image";

const NotFoundPag = () => (
  <>
    <Head>
      <title>Page not found</title>
      <meta content="index, follow" name="robots" />
    </Head>
    <div className="overflow-hidden h-screen w-screen relative">
      <div className="left-0 px-6 lg:px-0 ml-0 xl:ml-16 absolute top-1/2 -translate-y-1/2 h-max box-border w-screen">
        <div className="grid xl:grid-cols-3 grid-cols-2 gap-6 md:gap-8 mx-0">
          <div className="overflow-hidden fs-0">
            <Image
              layout="responsive"
              width={506}
              height={380}
              alt="look at our design"
              className="!w-full grayscale transition-all duration-500 ease-in-out hover:grayscale-0 hover:scale-110"
              loading="lazy"
              src="/assets/not-found/mrdonult.webp"
            />
          </div>
          <div className="overflow-hidden fs-0">
            <Image
              layout="responsive"
              width={506}
              height={380}
              alt="look at our design"
              className="w-full grayscale transition-all duration-500 ease-in-out hover:grayscale-0 hover:scale-110"
              loading="lazy"
              src="/assets/not-found/hasselblad.webp"
            />
          </div>
          <div className="overflow-hidden">
            <Image
              layout="responsive"
              width={506}
              height={380}
              alt="look at our design"
              className="w-full grayscale transition-all duration-500 ease-in-out hover:grayscale-0 hover:scale-110"
              loading="lazy"
              src="/assets/not-found/graham.webp"
            />
          </div>
          <div className="overflow-hidden">
            <Image
              layout="responsive"
              width={506}
              height={380}
              alt="look at our design"
              className="w-full grayscale transition-all duration-500 ease-in-out hover:grayscale-0 hover:scale-110"
              loading="lazy"
              src="/assets/not-found/dashboard.webp"
            />
          </div>
          <div className="col-start-2 sm:flex sm:items-center xl:row-start-2">
            <div>
              <h3 className="text-3xl pb-2 md:text-6xl font-bold !leading-tight">
                <span>404 Page Not Found</span>
              </h3>
              <PrimaryButton
                link={LINK_OF_ALL_PAGES.mainPages.home}
                val={"Get back to Home"}
              />
            </div>
          </div>
          <div className="overflow-hidden row-start-3 xl:row-start-2">
            <Image
              layout="responsive"
              width={506}
              height={380}
              alt="look at our design"
              className="w-full grayscale transition-all duration-500 ease-in-out hover:grayscale-0 hover:scale-110"
              loading="lazy"
              src="/assets/not-found/sculpture.webp"
            />
          </div>
          <div className="overflow-hidden">
            <Image
              layout="responsive"
              width={506}
              height={380}
              alt="look at our design"
              className="w-full grayscale transition-all duration-500 ease-in-out hover:grayscale-0 hover:scale-110"
              loading="lazy"
              src="/assets/not-found/payment-app.webp"
            />
          </div>
          <div className="overflow-hidden">
            <Image
              layout="responsive"
              width={506}
              height={380}
              alt="look at our design"
              className="w-full grayscale transition-all duration-500 ease-in-out hover:grayscale-0 hover:scale-110"
              loading="lazy"
              src="/assets/not-found/rediscover-your-self.webp"
            />
          </div>
          <div className="overflow-hidden">
            <Image
              layout="responsive"
              width={506}
              height={380}
              alt="look at our design"
              className="w-full grayscale transition-all duration-500 ease-in-out hover:grayscale-0 hover:scale-110"
              loading="lazy"
              src="/assets/not-found/social-app.webp"
            />
          </div>
          <div className="overflow-hidden block xl:hidden">
            <Image
              layout="responsive"
              width={506}
              height={380}
              alt="look at our design"
              className="w-full grayscale transition-all duration-500 ease-in-out hover:grayscale-0 hover:scale-110"
              loading="lazy"
              src="/assets/not-found/air-jordan.webp"
            />
          </div>
          <div className="overflow-hidden block xl:hidden">
            <Image
              layout="responsive"
              width={506}
              height={380}
              alt="look at our design"
              className="w-full grayscale transition-all duration-500 ease-in-out hover:grayscale-0 hover:scale-110"
              loading="lazy"
              src="/assets/not-found/construction-app.webp"
            />
          </div>
          <div className="overflow-hidden block xl:hidden">
            <Image
              layout="responsive"
              width={506}
              height={380}
              alt="look at our design"
              className="w-full grayscale transition-all duration-500 ease-in-out hover:grayscale-0 hover:scale-110"
              loading="lazy"
              src="/assets/not-found/news-app.webp"
            />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default NotFoundPag;
